<template>
  <v-card flat>
    <v-card-text class="pa-1">
      <template v-if="loadingBase">
        <div class="subtitle-1 mt-1">{{ $t('gettingFolders') }}</div>
        <v-progress-linear rounded height="6" indeterminate color="primary"></v-progress-linear>
      </template>
      <v-treeview v-else dense shaped hoverable class="tree--document" :style="{ 'minHeight': '40vh', 'maxHeight': '65vh', overflow: 'overlay' }"
        v-model="selectedNodesList" :load-children="getChildrenItems" selectable
        :open.sync="open" return-object :items="foldersList"><!-- activatable :active.sync="active" :active.sync="active" activatable @update:active="getFilesForFolder" leaf independent-->
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="item.is_document" :color="item.color">
            {{ item.icon }}
          </v-icon>
          <v-icon v-else color="#ffda6b">
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          <v-hover v-slot:default="{ hover }">
            <div class="text-truncate">
              <v-icon v-if="hover" small @click.stop="navigateToOD(item.webUrl)">mdi-open-in-new</v-icon>
              {{ item.name }}
            </div>
          </v-hover>
        </template>
      </v-treeview>
      <v-alert color="info" dark icon="mdi-cloud" border="left" prominent outlined v-if="foldersList.length <= 0 && !loadingBase">
        {{ $t('noFolders') }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
// import DEFAULT_PROP from './prop_types.json'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      MODULE_URL: 'sharepoint',
      open: ['public'],
      active: [],
      foldersList: [],
      loadingBase: false,
      documentsLoading: false,
      loading: false,
      alreadyOpened: [],
      alreadyActive: [],
      selectedNodesList: [],
      childCount: 0,
      listOfProperties: [],
      dynamicFormFields: [],
      copyDocumentsLoader: false,
      allParentNodes: false,
      allNodes: [],
      isLoadedFromRoot: true,
      rootFolderId: null
    }
  },
  computed: {
    ...mapGetters(['formType'])
  },
  mounted () {
    this.$api.execute('get', 'sharepointsetting').then((response) => {
      this.rootFolderId = response.data.root_folder_id
      this.getBaseFolders()
    })
    this.$eventBus.$on('copySelectedDocuments', () => {
      this.copySelectedDocuments()
    })
    // if (this.currentFolderAsRoot && this.currentFolder) {
    //   this.isLoadedFromRoot = false
    //   const baseFolder = { id: this.currentFolder.id, name: this.currentFolder.text, children: [], isbasefolder: true }
    //   this.foldersList.push(baseFolder)
    //   this.allNodes = [this.$format.cloneVariable(baseFolder)]
    //   this.open.push(baseFolder)
    // } else this.getBaseFolders()
  },
  methods: {
    getBaseFolders () {
      this.loadingBase = true
      this.foldersList = []
      this.listOfActiveFiles = []
      this.childCount = 0
      this.$api.execute('get', `${this.MODULE_URL}/get_base_folder_items`)
        .then((response) => {
          const folder = response.data
          const baseFolder = { id: folder.id, name: folder.name, children: [], webUrl: folder.weburl, childcount: folder.childcount, isbasefolder: true }
          this.foldersList.push(baseFolder)
          this.allNodes = [this.$formatter.cloneVariable(baseFolder)]
          const avaliableProps = response.data.fields || []
          this.dynamicFormFields = this.$formatter.cloneVariable(avaliableProps)
          avaliableProps.unshift({ title: this.$t('message.product.type') })
          this.listOfProperties = avaliableProps
          // this.prepareFieldsForProperties()
        }).finally(() => {
          this.alreadyOpened = this.$formatter.cloneVariable(this.open)
          this.alreadyActive = this.$formatter.cloneVariable(this.active)
          this.loadingBase = false
          this.open = this.alreadyOpened.map(x => x)
          this.active = []
          this.isLoadedFromRoot = true
        })
    },
    getChildrenItems (item) {
      const bkpList = this.$formatter.cloneVariable(this.selectedNodesList)
      return this.$api.execute('get', `${this.MODULE_URL}/get_subfolder_items/${item.id}`)
        .then((response) => {
          const children = response.data
          if (this.$formatter.isArrayHasData(children)) {
            for (let i = 0; i < children.length; i++) {
              const mimetypeObj = children[i].is_document ? this.$formatter.mimeTypeOfDocument(children[i].mimetype) : {}
              const childNode = {
                id: children[i].id,
                name: children[i].name,
                webUrl: children[i].weburl,
                children: children[i].childcount > 0 ? [] : undefined,
                is_document: children[i].is_document,
                childcount: children[i].childcount,
                parentId: item.id,
                ...mimetypeObj
              }
              item.children.push(childNode)
              if (this.$formatter.isArrayHasData(this.alreadyOpened)) {
                this.open.push(item)
              }
            }
            this.allNodes = [...this.allNodes, ...this.$formatter.cloneVariable(item.children)]
            this.childCount++
          }
        })
        .finally(() => {
          this.selectedNodesList = this.$formatter.cloneVariable(bkpList)
          if (this.$formatter.isArrayHasData(this.alreadyOpened)) {
            this.alreadyOpened.forEach(node => {
              this.open.push(...item.children.filter(x => x.id === node.id))
            })
            if (this.$formatter.isArrayHasData(this.alreadyActive)) {
              if (this.childCount === this.alreadyOpened.length) {
                this.active = this.alreadyActive.map(x => x)
                this.alreadyOpened = []
              }
            }
          }
        })
    },
    getSelectedNodeFiles (id) {
      this.documentsLoading = true
      this.$api.execute('get', `${this.MODULE_URL}/get_children_folders/${id}`)
        .then((response) => {
          const files = (response && response.data) ? response.data : []
          this.listOfActiveFiles = files
        })
        .finally(() => {
          this.documentsLoading = false
        })
    },
    navigateToOD (url) {
      window.open(url, '_blank')
    },
    // prepareFieldsForProperties () {
    //   const dynamicInputs = []
    //   this.dateFieldsForConversion = []
    //   for (let i = 0; i < this.dynamicFormFields.length; i++) {
    //     const field = this.dynamicFormFields[i]
    //     switch (field.type) {
    //       case DEFAULT_PROP.URL:
    //       case DEFAULT_PROP.TEXT:
    //       case DEFAULT_PROP.FILE:
    //       case DEFAULT_PROP.CURRENCY:
    //         dynamicInputs.push({ model: field.name, type: this.formType.TEXT, label: field.title, class: 'xs12' })
    //         break
    //       case DEFAULT_PROP.NUMBER:
    //       case DEFAULT_PROP.INTERGER:
    //         dynamicInputs.push({ model: field.name, type: this.formType.NUMBER, label: field.title, class: 'xs12' })
    //         break
    //       case DEFAULT_PROP.NOTE:
    //         dynamicInputs.push({ model: field.name, type: this.formType.TEXTAREA, label: field.title, class: 'xs12' })
    //         break
    //       case DEFAULT_PROP.BOOLEAN:
    //         dynamicInputs.push({ model: field.name, type: this.formType.CHECKBOX, label: field.title, class: 'xs12' })
    //         break
    //       case DEFAULT_PROP.CHOICE:
    //         dynamicInputs.push({ model: field.name, type: this.formType.AUTO_COMPLETE, items: field.choices, label: field.title, class: 'xs12' })
    //         break
    //       case DEFAULT_PROP.MULTICHOICE:
    //         dynamicInputs.push({ model: field.name, type: this.formType.AUTO_COMPLETE, items: field.choices, multiple: true, label: field.title, class: 'xs12' })
    //         break
    //       case DEFAULT_PROP.USER:
    //         dynamicInputs.push({ model: field.name, type: this.formType.AUTO_COMPLETE, items: field.users, select_value: 'id', select_text: 'name', label: field.title, class: 'xs12' })
    //         break
    //       case DEFAULT_PROP.DATETIME:
    //         this.dateFieldsForConversion.push(field.name)
    //         dynamicInputs.push({ model: field.name, type: this.formType.DATEPICKER, menu: `${field.name}_menu`, picker: `${field.name}_picker`, label: field.title, class: 'xs12', color: 'primary lighten-1' })
    //         this.$watch(`propertyModel.properties.${field.name}_picker`, (val) => {
    //           if (val) {
    //           // setTimeout(() => {
    //             this.propertyModel.properties[field.name] = this.$format.formatDate(val, 'YYYY-MM-DD', 'DD.MM.YYYY')
    //           // },100)
    //           }
    //         })
    //         break
    //       default:
    //         break
    //     }
    //   }
    //   this.formProps = dynamicInputs
    // },
    copySelectedDocuments () {
      const selectedNodes = this.$formatter.cloneVariable(this.selectedNodesList.filter(x => x.is_document))
      const modelList = selectedNodes.map(x => {
        return { id: x.id, isdocument: x.is_document, name: x.name, type: x.mimetype, is_sharepoint: true }
      })
      this.$emit('copyDocuments', modelList)
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('copySelectedDocuments')
  }
}
</script>

<style>
.v-treeview-node > .v-treeview-node__root {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.v-treeview-node > .v-treeview-node__root {
  min-height: 28px !important;
}
/* .v-treeview-node {
  max-height: 55vh;
} */
</style>
