var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-1"},[(_vm.loadingBase)?[_c('div',{staticClass:"subtitle-1 mt-1"},[_vm._v(_vm._s(_vm.$t('gettingFolders')))]),_c('v-progress-linear',{attrs:{"rounded":"","height":"6","indeterminate":"","color":"primary"}})]:_c('v-treeview',{staticClass:"tree--document",style:({ 'minHeight': '40vh', 'maxHeight': '65vh', overflow: 'overlay' }),attrs:{"dense":"","shaped":"","hoverable":"","load-children":_vm.getChildrenItems,"selectable":"","open":_vm.open,"return-object":"","items":_vm.foldersList},on:{"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.is_document)?_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_c('v-icon',{attrs:{"color":"#ffda6b"}},[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"text-truncate"},[(hover)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.navigateToOD(item.webUrl)}}},[_vm._v("mdi-open-in-new")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1)]}}],null,true)})]}}]),model:{value:(_vm.selectedNodesList),callback:function ($$v) {_vm.selectedNodesList=$$v},expression:"selectedNodesList"}}),(_vm.foldersList.length <= 0 && !_vm.loadingBase)?_c('v-alert',{attrs:{"color":"info","dark":"","icon":"mdi-cloud","border":"left","prominent":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('noFolders'))+" ")]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }